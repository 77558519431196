import { BaseURI } from "../..";
import { Executor } from "../../Executor";

export const getCommandeEnCours = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/commandeEnCours/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getMostRepeatedReference = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/mostRepeatedReferences/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getTagsNonActive = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/tags/non-active/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getLatestInventaireCount = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/client/${id}/latest-inventaire-count`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getEcartInventory = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/sum-ecart/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getAchats = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/achats/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getEcartAttribution = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/ecartAttribution/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getEcartParRapportInventory = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/sum-ecart-en-valeur/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getInventoryDifference = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/dashboard/getinventory/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};


/*Antenne Api */
export const getNumberOfArticlesPurshase = (id,startDate,endDate) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/count-articles-quantity/${id}?startDate=${startDate}&endDate=${endDate}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getTopSupliers = (id,startDate,endDate) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/top-5-fournisseur-by-quantity/${id}?startDate=${startDate}&endDate=${endDate}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getTotalAmountPayed = (id,startDate,endDate) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/total-montantht-payed-facturer/${id}?startDate=${startDate}&endDate=${endDate}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getTotalPartielPayed = (id,startDate,endDate) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/total-partiel-payed/${id}?startDate=${startDate}&endDate=${endDate}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getInvoiceToDeliverToday = (id) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/invoices-to-deliver-today/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};


export const getCustomerToDeliverToday = (id) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/getClientsToDeliverTodayByClient/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getLateInvoices = (id,startDate,endDate) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/late-invoices/${id}?startDate=${startDate}&endDate=${endDate}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getStock = (id) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/articles/summary/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getSortieQuantity = (id,note,startDate,endDate) => {
  return Executor({
    method: "get",
    url: `${BaseURI}/api/dashboard/tags/overview/${id}?note=${note}&startDate=${startDate}&endDate=${endDate}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};



